<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">댓글수정</h2>
			</div>
			<div class="save">
				<button
					@click="postComment"
					class="btn_save"
				>완료</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 글쓰기 영역 -->
		<div class="write_wrap pt-50 pb-50">
			<div class="container">
				<div class="one_editor">
					<div class="ct_text_editor">
						<!-- 글쓰기영역 -->
						<div class="ct_container">

							<div
								v-if="item_comment.comment_img_url"
								class="ct_imgarea"
							>
								<img
									:src="$request.upload_url(item_comment.comment_img_url)"
									style="width: 100%"
								/>
								<!-- TODO: 삭제기능필요 -->
								<button
									@click="is_confirm = true"
									class="item_close"
								><i class="icon-close"></i></button>
							</div>

							<div class="ct_textarea">
								<textarea
									v-model="item_comment.content"
									@input="reSize"
								></textarea>
							</div>
						</div>
						<!-- //글쓰기영역 -->
					</div>
				</div>
			</div>

			<div class="ct_button_area">

				<div
					v-if="item_comment.img_src"
					class="photo_area"
				>
					<div class="photo_area_view">
						<img
							v-if="item_comment.img_src"
							:src="$request.upload_url(item_comment.img_src)"
						/>
					</div>
					<button
						@click="item_comment.img_src = ''"
						class="view_close">
						<i class="icon-close"></i>
					</button>
				</div>
				<div class="container">
					<div class="toolbar_list_area">
						<ul class="toolbar_list toolbar_list-center">
							<li style="text-align: center; padding-top: 13px">
								<label for="albumFile"><span class="toolbar_icon tb_photo">사진</span></label>
								<input_file @change="setFile" accept="image/*" id="albumFile"/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<PopupConfirm
			v-if="is_confirm"

			@click="deleteImg"
			@cancel="is_confirm = false"
		>
			<template
				v-slot:title
			>이미지 삭제</template>
			<template
				v-slot:main-txt
			>첨부된 이미지를 삭제 하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>이미지를 삭제 하시려면 확인을 눌러주세요.</template>
		</PopupConfirm>
	</div>
</template>

<script>
	import input_file from '@/components/InputFile'
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'mafia0632'
		, components: {PopupConfirm, input_file}
		, props: ['user', 'cartel', 'bbs', 'comment']
		, data: function(){
			return {
				program: {
					name: '댓글 수정'
					, title: '댓글 수정'
					, not_header: true
					, not_footer: true
					, type: 'cartel_sub'
				}
				, item_comment: {
					content: ''
					, comment_img_url: ''
				}
				, is_confirm: false
				, upload_img: ''
			}
		}
		, methods:{
			getBbsComment: async function(){
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_bbs_comment
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
							, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
							, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
							, comment_number: this.$route.params.comment_id ? this.$route.params.comment_id : this.comment.comment_number
						}
						, type: true
					})

					if(result.success){
						this.item_comment = result.data
						this.item_comment.content = result.data.comment
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_confirm = false
					this.$bus.$emit('on', false)
				}
			}

			, postFile: async function(){
				try{
					this.$bus.$emit('on', true)
					if(!this.upload_img){
						return true
					}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700003'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: this.upload_img
						}
						, multipart: true
						, type: true
					})

					if(result.success){
						this.item_comment.comment_img_url = result.data.file_url
						return true
					}else{
						throw result.message
					}

				}catch (e) {
					this.$log.console('postFile error', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
					this.upload_img = ''
				}
			}
			, postComment: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw '댓글을 입력하세요'
					}
					this.$bus.$emit('on', true)
					if(await this.postFile()) {
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.post_comment_modify
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
								, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
								, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
								, comment_number: this.item_comment.comment_number
								, comment: this.item_comment.content
								, comment_img_url: this.item_comment.comment_img_url
							}
							, type: true
						})

						if (result.success) {
							this.$emit('click', this.item_comment)
							this.$bus.$emit('notify', {type: 'success', message: this.$language.common.success})
						}
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setFile: function(e){
				this.upload_img = e[0]
				this.$log.console('this.upload_img', this.upload_img)

				const reader = new FileReader()
				
				reader.onload = (e) => {
					// this.$log.console('onload', e)
					this.$set(this.item_comment, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e[0])
			}

			, reSize: function(e){
				e.target.style.height = 'auto'
				e.target.style.height = e.target.scrollHeight + 'px'
			}
			, goBack: function(){
				if(this.$route.params.comment_id){
					this.$emit('goBack')
				}else{
					this.$emit('cancel')
				}

			}
			, deleteImg: function(){
				this.item_comment.comment_img_url = '';
				this.is_confirm = false
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getBbsComment()

			this.$log.console('mafia0632', this.comment)
		}
	}
</script>