<template>
	<div
		class="position-fixed"
		style="left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; z-index: 9999"
	>
		<div class="bg-layer"></div>

		<div
			v-if="!is_select"
			class="position-relative viewer pa-20 flex-column justify-center"
			style="width: 100%; height: 100%;"
		>
			<article
				style="min-width: 320px; max-width: 95%; margin: 0 auto;"
				class="bg-white flex-column radius-20 box text-left pa-40-30 position-relative"
			>
				<div class="popup-close">
					<img class="cursor-pointer" @click="$emit('cancel')" :src="require('@/assets/image/web/notify_cartel/ico_close_bk.svg')" />
				</div>
				<div class="under-line flex-row justify-space-between items-center pb-20">
					<h3 class="size-px-20">댓글</h3>
					
					<div class="flex-row justify-space-between items-center">
						<h6
							v-if="is_mine"
							class="mr-10"
						>내가 쓴 댓글</h6>

					</div>
				</div>
				<div class="">
					<ul class="box-select pt-20">
						<li
							v-if="is_modify"
							@click="toModify" ><button>{{  $language.word.modify }}</button></li>
						<li
							v-if="is_modify"
							@click="onConfirm('delete_comment')"
						><button>{{  $language.word.delete }}</button></li>

						<template
							v-if="!is_mine"
						>

							<li
								@click="onSelect"
							><button>{{ $language.title.report_comment }}</button></li>

							<li
								v-if="is_friend"
							>
								<div
									@click="onConfirm('cancel_friend')"
									class="flex-row justify-space-between"
								>
									<span class="btn_left" style="color:#dc505c">{{  item_comment.comment_member_nickname }}</span>
									<button class="" style="color:#dc505c">{{  $language.word.friend_cancel }}</button>
								</div>
							</li>
							<li
								v-else
							>
								<div
									@click="onConfirm('add_friend')"
									class="flex-row justify-space-between"
								>
									<span class="btn_left" style="color: #3e47b7">{{  item_comment.comment_member_nickname }}</span>
									<button class="" style="color: #3e47b7">{{  $language.word.friend_add }}</button>
								</div>
							</li>
							<li
								v-if="is_black_list"
							>
								<div
									@click="onConfirm('block_cancel')"
									class="flex-row justify-space-between"
								>
									<span class="btn_left" style="color: #3e47b7">{{  item_comment.comment_member_nickname }}</span>
									<button class="" style="color: #3e47b7">{{  $language.word.block_cancel }}</button>
								</div>
							</li>
							<li
								v-else
							>
								<div
									@click="onConfirm('block_account')"
									class="flex-row justify-space-between"
								>
									<span class="btn_left" style="color:#dc505c">{{  item_comment.comment_member_nickname }}</span>
									<button class="" style="color:#dc505c">{{  $language.word.block }}</button>
								</div>
							</li>
						</template>
					</ul>
				</div>
			</article>
		</div>

		<PopupConfirm
			v-if="is_confirm"

			@click="doPost"
			@cancel="is_confirm = false"
		>
			<template
				v-slot:title
			>{{ $language.confirm[confirm_type].title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.confirm[confirm_type].main }}</template>
			<template
				v-slot:sub-txt
			>
				<template
					v-if="confirm_type == 'add_friend'"
				>
					<input v-model="is_notify" type="checkbox" id="mdChk">
					<label for="mdChk"> {{  $language.confirm[confirm_type].sub }}</label>
				</template>
				<template
					v-else
				>{{ $language.confirm[confirm_type].sub }} <template v-if="confirm_type=='report_comment'">{{item_reason.name}}</template></template>
			</template>
		</PopupConfirm>

		<PopupSelect
			v-if="is_select"
			@cancel="is_select = false"
			style="z-index: 1004"
		>
			<template
				v-slot:title
			>신고 사유</template>
			<template
				v-slot:list
			>
				<li
					v-for="(item, index) in reason"
					:key="'item_' + index"
					style="padding: 10px 0;"
					@click="setReason(item)"
				>{{ item.name }}</li>
			</template>
		</PopupSelect>
	</div>
</template>

<script>
import PopupConfirm from '@/view/Layout/PopupConfirm'
import PopupSelect from '@/view/Layout/PopupSelect'
export default {
	name: 'mafia062'
	, props: ['item_comment', 'user', 'bbs', 'cartel']
	, components: {PopupConfirm, PopupSelect}
	, data: function(){
		return {
			confirm_type: ''
			, is_confirm: false
			, is_delete: false
			, is_block: false
			, is_report: false
			, is_select: false
			, item_writer: {}
			, item_friend: {}
			, item_black: {}
			, is_notify: true
			, item_reason: {}
			, reason: [
				{ code: 'CA01300001', name: '부적절한 홍보'}
				, { code: 'CA01300002', name: '혐오 발언'}
				, { code: 'CA01300003', name: '음란성 또는 부적합한 내용'}
				, { code: 'CA01300004', name: '같은내용 반복'}
				, { code: 'CA01300005', name: '저작권 침해'}
				, { code: 'CA01300006', name: '거짓 정보 및 선동'}
			]
		}
	}
	, computed: {
		is_friend: function(){
			let t = false

			if(this.item_writer.member_friend_fg == 'Y'){
				t = true
			}
			return t
		}
		, is_mine: function(){
			let t = false
			if(this.user.member_number == this.item_writer.member_number){
				t = true
			}
			return t
		}
		, is_modify: function(){
			let t = false

			this.$log.console('this.item_writer.member_number == this.item_comment.comment_member_number', this.item_writer.member_number, this.item_comment.comment_member_number)
			if(this.item_writer.member_number == this.user.member_number){
				t = true
			}

			return t
		}
		, is_black_list: function(){
			let t = false

			if(this.item_writer.blok_member_fg == 'Y'){
				t = true
			}
			return t
		}
		, text_confirm_title: function(){
			let t = ''
			t = this.$language.confirm.title[this.confirm_type]
			return t
		}
	}
	, methods: {

		onConfirm: function(type){
			this.confirm_type = type
			this.is_confirm = true
			this.is_select = false
		}

		, onSelect: function(){
			this.is_select = true
		}
		, onBlock: function(){
			this.is_block = true
		}
		, onDelete: function(){
			this.is_delete = true
		}
		, onReport: function(){
			this.is_report = true
		}
		, getWriter: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_member_info
					, data: {
						member_number: this.user.member_number
						, inquiry_member_number: this.item_comment.comment_member_number
					}
					, type: true
				})

				if(result.success){
					this.item_writer = result.data.MmbBasicInfo
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postFriend: async function(){
			try {
				let url = this.$api_url.api_path.post_friend_add
				if(this.is_friend){
					url = this.$api_url.api_path.post_friend_cancel
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, friend_member_number: this.item_writer.member_number
						, note_fg: this.is_notify ? 'Y' : 'N'
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, deleteComment: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.delete_comment
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
						, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
						, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
						, comment_number: this.item_comment.comment_number
					}
					, type: true
				})

				if(result.success){
					this.$emit('delete')
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_delete = false
				this.is_confirm = false
			}
		}

		, postBlockMember: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_black_add
					, data: {
						member_number: this.user.member_number
						, blok_member_number: this.item_writer.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, postBlockCancel: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_black_cancel
					, data: {
						member_number: this.user.member_number
						, blok_member_number: this.item_writer.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getWriter()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_confirm = false
				this.$bus.$emit('on', false)
			}
		}
		, postReportComment: async function(){
			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_report_comment
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
						, comment_number: this.item_comment.comment_number
						, comment_declaration_reason_code: this.item_reason.code
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_delete = false
				this.is_confirm = false
			}
		}
		, doPost: function(){
			switch (this.confirm_type){
				case 'add_friend':
					this.postFriend()
					break;
				case 'cancel_friend':
					this.postFriend()
					break;
				case 'block_account':
					this.postBlockMember()
					break;
				case 'block_cancel':
					this.postBlockCancel()
					break;
				case 'delete_comment':
					this.deleteComment()
					break;
				case 'report_comment':
					this.postReportComment()
					break;
				default:
					this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_confirm_type})
					break;
			}
		}
		, mount: async function(){
			await this.getWriter()
		}
		, setReason: function(item){
			this.item_reason = item
			this.onConfirm('report_comment')
		}
		, toModify: function(){
			this.$emit('modify')
			/*
			this.$bus.$emit('to', { name: 'mafia0632', params: { idx: this.$route.params.idx, code: this.item_bbs.board_type_code, b_id: this.item_bbs.board_number, bbs_id: this.item_bbs.bulletin_number, comment_id: this.item_comment.comment_number, from: this.$route.name} })

			 */
		}
	}
	,async created() {
		await this.mount()
	}
}
</script>